import React from 'react';
import PropTypes from 'prop-types';

import { ContentRow, ContentBlock } from 'components/UI';

const Hotels = ({ center, alternate, className }) => {
  const imageUrl =
    'https://d1m2xmyf58uf17.cloudfront.net/property_images/0056430/32283/medium/HE_MANSION_exteriors_1.jpg?1548708475';

  return (
    <ContentRow center={center} alternate={alternate} className={className}>
      <div>
        <img src={imageUrl} alt="Pretty" />
      </div>
      <ContentBlock>
        <header>
          <h3>
            <strong>Hotels, Rates, perks, and more</strong>

            <div className="sub">We don’t show you everything, only&nbsp;the&nbsp;best</div>
          </h3>
        </header>
        <p>
          Skylark is a trusted preferred partner of more than 1,500 luxury hotels worldwide. This means you get insider
          deals, daily breakfast, free upgrades, resort credits, special experiences like tequila tastings, cooking
          classes, and more.
        </p>
      </ContentBlock>
    </ContentRow>
  );
};

Hotels.propTypes = {
  alternate: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default Hotels;
